import React, { useEffect, useState } from 'react';
import { getter } from './utils';
import moment from 'moment-timezone';
import { Button, Box, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import SelectMUI from 'components/mui/select/select.component';
import Carousel from 'react-material-ui-carousel';
import { ArrowBackIcon, ArrowForwardIcon, Calendar24HoursIcon, CalendarAddonIcon, CarrotDownIcon, CarrotUpIcon, DescriptionIcon, LocationOnIcon, SteppersIcon } from 'components/mui';
import CircularProgress from '@mui/material/CircularProgress';
const fieldsToExclude = ['location', 'appointment_naive', 'status', 'type', 'description'];
export function SchedulesComponent({
  selectedSchedule,
  ...props
}) {
  const {
    schedules,
    availableModelsFields,
    availableModels,
    _detailsTabs,
    handleDetailsTab,
    selectedDetailsTab,
    isLoading
  } = props;
  const [selectedScheduleIndex, setSelectedScheduleIndex] = useState(0);
  const [showMoreFields, setShowMoreFields] = useState(false);
  useEffect(() => {
    let toSelect = 0;
    if (selectedSchedule && schedules) {
      toSelect = schedules.findIndex(s => s.id === selectedSchedule);
      if (toSelect === -1) {
        toSelect = 0;
      }
    }
    setSelectedScheduleIndex(toSelect);
  }, [selectedSchedule, schedules]);
  const scheduleFields = availableModelsFields.find(model => model.id === 'schedule').fields.filter(f => !fieldsToExclude.includes(f.id));
  return <>
      <Stack sx={{
      height: '100%',
      background: '#E4E9F1',
      borderBottomLeftRadius: '28px',
      borderBottomRightRadius: '28px',
      overflowY: 'scroll',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        marginTop: '25px',
        marginBottom: '25px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }} className="d-flex h-100" spacing={'14px'}>
        <Box className="d-flex w-100  justify-content-between" sx={{
        position: 'relative',
        pt: '14px',
        pb: '38px',
        display: 'flex'
      }}>
          <span />
          <Box sx={{
          position: 'absolute',
          left: '37.5%'
        }}>
            <SelectMUI height="36px" width="180px" top="6px" zIndex={9001} options={_detailsTabs} value={selectedDetailsTab || ''} onChange={opt => handleDetailsTab(opt.key)} />
          </Box>
          {schedules?.length > 0 && <Box sx={{
          position: 'absolute',
          left: '77%',
          top: '24px'
        }}>
              {'' + (selectedScheduleIndex + 1) + ' of ' + schedules?.length + ' Items'}
            </Box>}
        </Box>
        <Stack>
          {isLoading ? <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: '25px'
        }}>
              <CircularProgress />
            </Box> : <>
              {schedules?.length > 0 ? <Carousel index={selectedScheduleIndex} navButtonsAlwaysVisible cycleNavigation={false} autoPlay={false} animation="slide" indicators={false} PrevIcon={<ArrowBackIcon fill="#fff" />} NextIcon={<ArrowForwardIcon fill="#fff" />} onChange={(now, prev) => setSelectedScheduleIndex(now)}>
                  {schedules.map((schedule, i) => <Box key={i} sx={{
              px: '50px'
            }}>
                      <Paper sx={{
                borderRadius: '10px',
                overflowY: 'scroll',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  marginTop: '10px',
                  marginBottom: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888'
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555'
                },
                height: '68vh'
              }}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <LocationOnIcon />
                                  <Typography variant="tableHeader">Location </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {schedule?.location_friendly_name || <Typography variant="nullData" color={'#BEBEBE'}>
                                    <span>--</span>{' '}
                                  </Typography>}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Calendar24HoursIcon />
                                  <Typography variant="tableHeader">Appointment </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {schedule?.appointment_naive ? moment.utc(schedule.appointment_naive).format('MMM D, YYYY h:mm A') : <Typography variant="nullData" color={'#BEBEBE'}>
                                    <span>--</span>{' '}
                                  </Typography>}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <CalendarAddonIcon />
                                  <Typography variant="tableHeader">Type </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {' '}
                                {schedule?.type || <Typography variant="nullData" color={'#BEBEBE'}>
                                    <span>--</span>{' '}
                                  </Typography>}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <SteppersIcon />
                                  <Typography variant="tableHeader">Status </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {' '}
                                {schedule?.status || <Typography variant="nullData" color={'#BEBEBE'}>
                                    <span>--</span>{' '}
                                  </Typography>}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <DescriptionIcon />
                                  <Typography variant="tableHeader">Description </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {' '}
                                {schedule?.description || <Typography variant="nullData" color={'#BEBEBE'}>
                                    <span>--</span>{' '}
                                  </Typography>}
                              </TableCell>
                            </TableRow>
                            {availableModels.indexOf('schedule') > -1 && scheduleFields
                    //.filter((f) => schedule[f?.id] != undefined && schedule[f?.id] != null && schedule[f?.id] != '')
                    .filter((field, index) => showMoreFields || index < 6).map((field, index) => {
                      const fieldValue = schedule[field.id];
                      return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' ? <TableRow key={index}>
                                      <TableCell>
                                        <Typography variant="tableHeader">{field.name} </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {field.type === 'datetime' && field.name == 'Appointment UTC' ? moment(fieldValue).utc().format('MMM D, YYYY h:mm A') : field.type === 'datetime' ? moment(fieldValue).local().format('MMM D, YYYY h:mm A') : field.type === 'date' ? moment(fieldValue).format('MMM D, YYYY') : fieldValue}
                                      </TableCell>
                                    </TableRow> : field.id.substring(0, 13) === 'entity_data__' ? <TableRow key={index}>
                                      <TableCell>
                                        <Typography>{field.name} </Typography>
                                      </TableCell>
                                      <TableCell>{getter(schedule, field.id.split('__')) || <i>No Data Available</i>}</TableCell>
                                    </TableRow> : <React.Fragment key={field.id} />;
                    })}
                            {scheduleFields.length > 6 && <TableRow sx={{
                      cursor: 'pointer'
                    }} onClick={() => setShowMoreFields(!showMoreFields)}>
                                <TableCell sx={{
                        py: '8px'
                      }}>
                                  <Typography sx={{
                          color: '#1D252D80'
                        }}>
                                    {'... ' + (scheduleFields.length - 6) + (showMoreFields ? ' less items' : ' more items')}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{
                        py: '8px'
                      }}>
                                  <Box className="d-flex  justify-content-between">
                                    <span />
                                    <Button>
                                      <Stack sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }} direction="row" spacing="10px">
                                        <Typography sx={{
                                color: '#3898D9',
                                textTransform: 'none'
                              }}>
                                          {showMoreFields ? 'View less' : 'View all'}
                                        </Typography>
                                        {showMoreFields ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                      </Stack>
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>}
                          </TableBody>
                        </Table>
                      </Paper>
                    </Box>)}
                </Carousel> : <Stack>
                  <Box sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}>
                    {/* <div className="my-5"> */}
                    {/* <em>Customer has no transactions</em> */}
                    <Typography>Customer has no schedules</Typography>
                    {/* </div> */}
                  </Box>
                </Stack>}
            </>}
        </Stack>
      </Stack>
    </>;
}