import React, { useState, useContext, useEffect } from 'react';
import { bool, func } from 'prop-types';
import { Scoped, k } from 'kremling';
import { isFunction, get } from 'lodash';
import { Button } from 'components/button/button.component';
import { Modal } from 'components/modal/modal.component';
import { FormWizardSteps } from './form-wizard-steps.component';
import { FormWizardDetails } from './form-wizard-details.component';
import { api } from 'shared/api';
import { FormWizardChooser } from './form-wizard-chooser.component';
import { FormWizardMapper } from './form-wizard-mapper.component';
import { UserStateContext } from 'context/user-state-context';
import { Loader } from 'components/loader/loader.component';
import { FormWizardScript } from './form-wizard-script.component';
import { postForm } from 'shared/forms.api';
import { validateUrl } from './form-wizard.util';
import { FormWizardVerify } from './form-wizard-verify.component';
import { ModalContext } from 'context/modal-context';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
export const FormWizard = ({
  open,
  onClose
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const {} = useContext(ModalContext);
  const [step, setStep] = useState(1);
  const [searchingForForms, setSearchingForForms] = useState(false);
  const [saving, setSaving] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [forms, setForms] = useState([]);
  const [showFormRedirect, setShowFormRedirect] = useState(false);
  const [formName, setFormName] = useState('');
  const [formId, setFormId] = useState();
  const [formUrl, setFormUrl] = useState('');
  const [formRedirect, setFormRedirect] = useState('');
  const [formFields, setFormFields] = useState([]);
  const [formHtmlIndex, setFormHtmlIndex] = useState(0);
  const [formHtmlTag, setFormHtmlTag] = useState(null);
  const [formVerified, setFormVerified] = useState(false);
  useEffect(() => {
    if (step === 2 && shouldSearch && !searchingForForms) {
      searchForForms();
    }
  }, [step, shouldSearch, searchingForForms]);
  const searchForForms = () => {
    setSearchingForForms(true);
    setShouldSearch(false);
    api.post(`/webform/scrape`, {
      url: formUrl
    }).then(({
      data
    }) => {
      if (data && data.length && data[0] && data[0].fields) {
        setForms(data);
        setFormFields(data[0].fields.map(field => ({
          ...field,
          field: null
        })));
        setFormHtmlIndex(0);
        setSearchingForForms(false);
        setShouldSearch(false);
      } else {
        throw new Error('No forms found');
      }
    }).catch(err => {
      setForms([]);
      setFormFields([]);
      setFormHtmlIndex(0);
      setSearchingForForms(false);
    });
  };
  const closeModal = () => {
    setStep(1);
    setSearchingForForms(false);
    setSaving(false);
    setShouldSearch(true);
    setForms([]);
    setShowFormRedirect(false);
    setFormName('');
    setFormUrl('');
    setFormRedirect('');
    setFormFields([]);
    setFormHtmlIndex(0);
    setFormHtmlTag(null);
    setFormId(null);
    setFormVerified(false);
    onClose();
  };
  const validateStep1 = () => {
    return !!(formName && formUrl && validateUrl(formUrl) && (!formRedirect || validateUrl(formRedirect)));
  };
  const validateStep2 = () => {
    return !!(forms.length && formFields.length);
  };
  const validateStep3 = () => {
    return true;
  };
  const validateSteps = step => {
    if (step === 1) {
      return validateStep1();
    }
    if (step === 2) {
      return validateStep2();
    }
    return validateStep3();
  };
  const save = () => {
    setSaving(true);
    postForm({
      company: asCompany.id,
      name: formName,
      url: formUrl,
      redirect: formRedirect,
      fields: formFields,
      html_index: formHtmlIndex,
      html_tag: formHtmlTag
    }).then(({
      data
    }) => {
      setStep(4);
      setFormId(data.id);
      setSaving(false);
    });
  };
  const updateState = state => {
    Object.keys(state).forEach(key => {
      if (key === 'formUrl') {
        setFormUrl(state[key]);
      } else if (key === 'shouldSearch') {
        setShouldSearch(state[key]);
      } else if (key === 'forms') {
        setForms(state[key]);
      } else if (key === 'formFields') {
        setFormFields(state[key]);
      } else if (key === 'showFormRedirect') {
        setShowFormRedirect(state[key]);
      } else if (key === 'formName') {
        setFormName(state[key]);
      } else if (key === 'formRedirect') {
        setFormRedirect(state[key]);
      } else if (key === 'formHtmlIndex') {
        setFormHtmlIndex(state[key]);
        setFormFields(forms[state[key]].fields);
      }
    });
  };
  const verifyLater = () => {
    snackbarService.popup({
      type: 'warning',
      message: 'Warning: This webform cannot be used in journeys until it has been verified by sending a test submission'
    });
    closeModal();
  };
  const verify = () => {
    snackbarService.popup({
      type: 'success',
      message: 'This web form is now available to use in journeys'
    });
    closeModal();
  };
  return <Modal open={open} size="lg" type="scroll" title="Track New Form" onClose={closeModal}>
      <Scoped css={css}>
        <div className="modal__body">
          {!saving && <FormWizardSteps step={step} changeStep={setStep} step1Validated={validateStep1()} step2Validated={validateStep2()} step3Validated={validateStep3()} />}
          {step === 1 && !saving && <FormWizardDetails formRedirect={formRedirect} showFormRedirect={showFormRedirect} updateState={updateState} formUrl={formUrl} formName={formName} />}
          {step === 2 && !saving && <FormWizardChooser searchingForForms={searchingForForms} forms={forms} updateState={updateState} formHtmlIndex={formHtmlIndex} />}
          {step === 3 && !saving && <FormWizardMapper formFields={formFields} updateState={updateState} />}

          {step === 4 && !saving && <FormWizardScript formId={formId} />}

          {step === 5 && !saving && <FormWizardVerify formFields={formFields} formId={formId} setFormVerified={setFormVerified} formVerified={formVerified} />}

          {saving && <div className="wizard-saving">
              Saving
              <Loader />
            </div>}
        </div>
        {!(searchingForForms || saving) && <div className="new-form-actions">
            <Button actionType="flat" onClick={closeModal}>Cancel</Button>
            <div className="new-form-actions__right">
              {step > 1 && step < 4 && <Button onClick={() => setStep(step - 1)}>
                  Back
                </Button>}
              {step < 3 && <Button actionType="primary" onClick={() => setStep(step + 1)} disabled={!validateSteps(step)}>
                  Next
                </Button>}
              {step === 3 && <Button actionType="primary" onClick={save}>
                  Next
                </Button>}
              {step === 4 && <Button actionType="primary" onClick={() => setStep(step + 1)}>
                  Next
                </Button>}
              {step === 5 && !formVerified && <Button actionType="primary" onClick={verifyLater}>
                  Verify Later
                </Button>}
              {step === 5 && !!formVerified && <Button actionType="primary" onClick={verify}>
                  Verify
                </Button>}
            </div>
          </div>}
      </Scoped>
    </Modal>;
};
const css = {
  styles: `[kremling="i25"] body,body[kremling="i25"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i25"] .new-form-actions,[kremling="i25"].new-form-actions {
  border-top: solid 0.1rem var(--color-grey-100);
  padding: 1.6rem;
  text-align: right;
}

[kremling="i25"] .new-form-actions,[kremling="i25"].new-form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

[kremling="i25"] .new-form-actions__right button,[kremling="i25"].new-form-actions__right button {
  margin-left: 1.6rem;
}

[kremling="i25"] .new-form-actions__right,[kremling="i25"].new-form-actions__right {
  display: flex;
  align-items: center;
  justify-content: center;
}

[kremling="i25"] .wizard-saving,[kremling="i25"].wizard-saving {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30rem;
  font-weight: 700;
  font-size: 1.6rem;
}`,
  id: 'i25',
  namespace: 'kremling'
};